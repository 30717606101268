<template>
	<div>
		<div class="homeLeftMenu">
			<div class="homeLeftMenuItem" v-for="(item,index) in leftMenu" :key="index" @click="$goto(item.path)">
				<img style="width:30px" :src="item.img" alt="">
				<p style="width:40px;text-align:center;margin-top:10px">{{item.title}}</p>
			</div>
		</div>
		<TitleMore :title="['我要捐款','我要查询']" :isMore="true" :isDonate="true" moreTitle='捐赠须知' @handleTab="handleTab"
			@handleMore="handleMore"></TitleMore>
		<IdonateThing v-show="activeIndex==2"></IdonateThing>
		<!-- <DonateSearch v-show="activeIndex==2"></DonateSearch> -->
		<el-dialog :title="donateInfo.title" :visible.sync="dialogVisible">
			<div v-html="donateInfo.text"></div>
		</el-dialog>
		<el-dialog :visible.sync="showTip" width="610px" class="tip-dialog" :close-on-click-modal='false'>
			<div class="tip-box" slot="title">温馨提示</div>
			<div class="tip-content">
				<p>此为四川省红十字会官方捐赠通道，感谢您的信任。
				<p>捐赠是自愿无偿、奉献爱心的人道行为，
				<b style="font-weight: bold;">捐款无任何返利返佣。</b>
				若有其他不明情况，可来电咨询(028-84350282)。
				</p>
			</div>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" type="danger" @click="toJk()">确定</el-button>
			  <el-button size="mini" @click="showTip = false">取消</el-button>
			</div>
		</el-dialog>
		<div class="moneyCard" v-show="activeIndex==0">
			<div class="selectCard">
				<p class="selectCardItem" :class="[selectIndex==index?'activeSelect':'']"
					v-for="(item,index) in selectData" :key="index" @click="activeSelect(index,item.type)">
					{{item.title}}</p>
			</div>

			<div class="donateFormContent" v-show="selectIndex==0">
				<p class="donateTitle">爱心无论大小，善举不分先后。所有善款，将全部用于爱心公益事业 ，予人玫瑰，手留余香，感谢您的善行！</p>
				<p class="donateHint">请正确填写捐款人信息。标注“*”为必须填写的选项。</p>
				<p class="donateHint">您10元及以下的捐款将进入网上小额募捐箱，汇聚统一用于符合红十字宗旨的人道公益事业。</p>
				<div class="formContent">
					<el-form :inline="true" ref="ruleForm" label-width="100px" label-position="left" :model="formInline"
						:rules="rules" class="donateForm">
						<el-form-item class="formItemAll" label="捐赠意向" prop="donatorIntention">

							<el-select style="width:100%" v-model="formInline.donatorIntention" :disabled="isReadonly"
								placeholder="请选择">
								<el-option v-for="item in ListData" :label="item.title" :value="item.id" :key="item.id"
									:title="item.metaDescription"></el-option>

							</el-select>

						</el-form-item>
						<p class="donateHint" style="line-height: 20px;padding:0 10px 10px;margin-top:-10px">
							“符合红十字宗旨的人道公益事业”为非定向捐赠，捐赠资金将由四川省红十字会统筹安排使用。</p>
						<el-form-item class="formItemAll" label="金额" prop="amount">
							<div class="amountMain">
								<p :class="[formInline.amount==item.value?'amountCardActive':'amountCard']"
									@click="chooseAmount(item.value)" v-for="item in amountData" :key="item.value">
									{{item.label}}</p>
							</div>
							<div class="amountInput">
								<p style="width:100px">其他金额</p>
								<el-input style="width:50%" v-model="amountVal" @input="inputAmount" placeholder="请输入">
								</el-input>
								<p style="margin-left:10px">元</p>
							</div>
						</el-form-item>
						<el-form-item label="捐赠人姓名（票据抬头）" class="formItemAll">
							<el-input style="width:60%" v-model="donatorName" placeholder="请输入实名"></el-input>
							<el-checkbox style="margin-left:20px" v-model="anonIs">匿名(爱心人士)</el-checkbox>
						</el-form-item>

						<el-form-item label="联系电话" class="formItemAll">
							<el-input v-model="formInline.contackPhone" placeholder="请输入"></el-input>
						</el-form-item>
						<el-form-item label="是否需要票据" style="width:48%">
							<el-radio-group v-model="formInline.invoiceIs" @change="changeInvoice">
								<el-radio :label="item.code" v-for="item in dictionaryArr" :key="item.code">
									{{item.name}}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="是否需要捐赠证书" label-width="120" style="width:48%">
							<el-radio-group v-model="formInline.certificateIs" @change="changeCertificate">
								<el-radio :label="item.code" v-for="item in dictionaryArr" :key="item.code">
									{{item.name}}</el-radio>
							</el-radio-group>
						</el-form-item>

						<!-- 电子邮箱(接收电子票据/证书) -->
						<el-form-item label-width="105px" label="电子邮箱(接收电子票据/证书)" class="formItemAll" :prop="emailProp">
							<el-input v-model="formInline.email" placeholder="请输入" @input="emailChange"></el-input>
							<p v-if="emailPropShow" style="color: #F56C6C; font-size: 12px">请输入电子邮箱</p>
						</el-form-item>
						<!-- <el-form-item
              label="捐赠留言"
              style="width:100%"
              class="remark formItemAll"
            >
              <el-input
                style="width:100%"
                type="textarea"
                v-model="formInline.donatorMessage"
                placeholder="请输入"
              ></el-input>
            </el-form-item> -->
						<div class="btns">
							<el-button class="submitBtn" @click="onSubmit">立即捐款</el-button>
							<el-button class="cancelBtn" @click="resetForm">取消填写</el-button>
						</div>
					</el-form>
					<div class="formRight">
						<div class="ercodeMain">
							<img :src="ercode.largeImage" alt="">
							<p style="margin-top:10px">使用微信扫码捐赠</p>

						</div>
						<p style="font-size:14px;color:#777777;margin-top:20px">您还可以留下您的联系方式，方便我们为您服务</p>
						<div class="formRightInput">
							<p class="formRightLabel">微信</p>
							<el-input placeholder="请输入微信" prefix-icon="el-icon-search" v-model="formInline.wechat">
							</el-input>
						</div>
						<div class="formRightInput">
							<p class="formRightLabel">QQ</p>
							<el-input placeholder="请输入qq" prefix-icon="el-icon-search" v-model="formInline.qq">
							</el-input>
						</div>
						<div class="formRightInput">
							<p class="formRightLabel">地址</p>
							<el-cascader style="width:100%" v-model="addressCity" :options="addressData"
								@change="selectAddress"></el-cascader>

						</div>
						<div class="formRightInput">
							<el-input v-model="formInline.address" placeholder="请填写详细地址"></el-input>
						</div>

					</div>
				</div>

			</div>
			<Bank v-show="selectIndex==1"></Bank>
			<PostOffice v-show="selectIndex==2"></PostOffice>
			<Message v-show="selectIndex==3"></Message>
			<Company v-show="selectIndex==4"></Company>
		</div>
		<el-dialog title="支付二维码（微信或支付宝）" :visible.sync="visible" width="20%">
			<img style="width:100%" :src="codeUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
	import TitleMore from "@components/TitleMore";
	import IdonateThing from "@components/Idonate/iDonateThing";
	import DonateSearch from "@components/Idonate/donateSearch";
	import Bank from "@components/Idonate/bank";
	import PostOffice from "@components/Idonate/postOffice";
	import Company from "@components/Idonate/company";
	import Message from "@components/Idonate/message";
	import apiUrls from "@api";
	import regions from "@utils/regions";
	import Bus from '../../utils/bus.js'
	export default {
		data() {
			return {
				activeIndex: 0,
				selectIndex: 0,
				amountVal: 0,
				showTip:false,
				amountData: [{
						label: "10元",
						value: 10,
					},
					{
						label: "20元",
						value: 20,
					},
					{
						label: "50元",
						value: 50,
					},
					{
						label: "100元",
						value: 100,
					},
					{
						label: "200元",
						value: 200,
					},
				],
				selectData: [{
						title: "在线捐款"
					},
					{
						title: "银行捐款",
						type: "yhjk"
					},
					{
						title: "邮局电汇",
						type: "yjdh"
					},
					{
						title: "短信捐款",
						type: "dxjk"
					},
					// { title: "企业捐赠", type: "smjz" },
				],
				addressCity: "",
				addressInfo: "",
				donatorName: "",
				anonIs: false,
				emailPropShow: false,
				emailProp: "",
				invoiceIs: "1",
				certificateIs: "1",
				formInline: {
					donatorName: "",
					donatorIntention: "",
					currency: "RMB",
					amount: "",
					email: "",
					contackPhone: "",
					// donatorMessage: "",
					anonIs: "1",
					invoiceIs: "1",
					certificateIs: "1",
					wechat: "",
					qq: "",
					province: "",
					city: "",
					county: "",
					address: ""
				},
				isReadonly: false,
				rules: {
					donatorIntention: [{
						required: true,
						message: "请选择捐赠意向",
						trigger: "change",
					}, ],
					amount: [{
						required: true,
						message: "请输入金额",
						trigger: "blur"
					}],
					contackPhone: [{
						required: true,
						message: "请输入联系人电话",
						trigger: "blur"
					}, ],
					email: [{
						required: true,
						message: "请输入电子邮箱",
						trigger: "blur"
					}],
				},
				ListData: [],
				isLimit: "",
				dictionaryArr: [],
				codeUrl: "",
				visible: false,
				dialogVisible: false,
				thing: {},
				type: "wyjw",
				donateInfo: "",
				ercode: {},
				addressData: [],
				leftMenu: [{
						img: require("../../assets/img/help.png"),
						title: "我要求助",
						path: "/help",
					},
					{
						img: require("../../assets/img/idonate.png"),
						title: "我要捐赠",
						path: "/idonate",
					},
					{
						img: require("../../assets/img/complaint.png"),
						title: "我要参与",
						path: "/complain",
					},
					{
						img: require("../../assets/img/bossemail.png"),
						title: "会长信箱",
						path: "/email",
					},
				],

				flag: false
			};
		},

		components: {
			TitleMore,
			IdonateThing,
			DonateSearch,
			Bank,
			PostOffice,
			Message,
			Company,
		},

		mounted() {
			if (this._isMobile()) {
				console.log('111')
				Bus.$emit('val', this.flag)
				this.$router.replace('/idonateAPP');
			}

			this.getList();
			this.getDictionary();
			this.getercode();
			this.addressData = this.getAddress(regions);

		},

		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			},

			inputAmount(val) {
				if (!isNaN(val)) {
					val <= 0 ?
						(this.formInline.amount = 10) :
						(this.formInline.amount = val);
					if (val <= 10 && this.isLimit) {
						this.formInline.donatorIntention = 19367;
						this.isReadonly = true;
					} else {
						this.isReadonly = false;
					}
				} else {
					this.$message({
						message: "请输入数字",
						type: "warning",
					});
				}
			},
			changeCertificate(val) {
				console.log(this.emailProp = val == 0 || this.invoiceIs == 0)
				this.certificateIs = val;
				this.emailProp = val == 0 || this.invoiceIs == 0 ? "email" : "";
				console.log(this.emailProp)
			},
			changeInvoice(val) {
				this.invoiceIs = val;
				this.emailProp = val == 0 || this.certificateIs == 0 ? "email" : "";
			},
			emailChange(val) {
				if (this.emailProp == 'email' && val) {
					this.emailPropShow = false
				}
			},
			chooseAmount(val) {
				this.formInline.amount = val;
				this.amountVal = 0;
				if (val == 10 && this.isLimit) {
					this.isReadonly = true;
					this.formInline.donatorIntention = 19367;
				} else {
					this.isReadonly = false;
				}
			},
			handleTab(index) {
				this.activeIndex = index;
				index == 1 && this.$router.push("/donateInfo");
			},
			activeSelect(index, type) {
				this.selectIndex = index;
				this.type = type;
			},
			onSubmit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						if (this.emailProp == 'email' && !this.formInline.email) {
							this.emailPropShow = true
						} else {
							this.emailPropShow = false
							this.anonIs ?
								((this.formInline.anonIs = this.dictionaryArr[0].code),
									(this.formInline.donatorName = "爱心人士")) :
								((this.formInline.anonIs = this.dictionaryArr[1].code),
									(this.formInline.donatorName = this.donatorName || "爱心人士"));
								this.showTip = true;
						}
					} else {
						return false;
					}
				});
			},
			toJk(){
				apiUrls.donate({
					...this.formInline
				}).then((res) => {
					this.showTip = false;
					if (res.rCode == 0) {
						this.codeUrl = res.results.qrImage;
						this.visible = true;
					}
				}).catch(e=>{
					alert("网络错误!")
					this.showTip = false;
				});
			},
			resetForm() {
				this.$refs.ruleForm.resetFields();
				this.formInline = {
					donatorName: "",
					donatorIntention: "",
					currency: "RMB",
					amount: "",
					email: "",
					contackPhone: "",
					// donatorMessage: "",
					anonIs: "no",
					invoiceIs: "no",
					certificateIs: "",
					wechat: "",
					qq: "",
					province: "",
					city: "",
					county: "",
				};
			},
			getList() {
				apiUrls.selfDynamicList({
					node: "rdxm_xwlb"
				}).then((res) => {
					this.ListData = res.results.data;
					var length = res.results.data.length;
					this.$route.query.projectId ?
						(this.formInline.donatorIntention = parseInt(this.$route.query.projectId)) :
						(this.formInline.donatorIntention = res.results.data[0].id); //默认选第一条
					for (var i = 0; i < length; i++) {
						if (res.results.data[i].id == 19367) {
							this.isLimit = true
							break;
						}
					}
				});
			},
			getDictionary() {
				apiUrls.dictionaryId({
					dictionaryId: "yesnocode"
				}).then((res) => {
					this.dictionaryArr = res.results;
					this.formInline.anonIs = this.dictionaryArr[1].code;
					this.formInline.invoiceIs = this.dictionaryArr[1].code;
					this.formInline.certificateIs = this.dictionaryArr[1].code;
				});
			},

			handleMore() {
				this.dialogVisible = true;
				apiUrls.trainNum({
					node: "zxjz_jzxz"
				}).then((res) => {
					this.donateInfo = res.results.data;
				});
			},
			getercode() {
				apiUrls.trainNum({
					node: "smjz"
				}).then((res) => {
					this.ercode = res.results.data;
				});
			},
			getAddress(arr = []) {
				arr = Array.from(arr);
				arr.map((el) => {
					el.value = el.label;
					if (el.children && el.children.length > 0) {
						this.getAddress(el.children);
					}
					return el;
				});
				return arr;
			},
			selectAddress(val) {
				this.formInline.province = val[0];
				this.formInline.city = val[1];
				this.formInline.county = val[2];
			},
		},
	};
</script>
<style lang="less" scoped>
	.tip-dialog{
		/deep/ .el-dialog__body{
			padding: 20px 7px;
		}
	}
	.tip-box{
		text-align: center;
		font-weight: bold;
		font-size: 24px;
	}
	.tip-content{
		height: 128px;
		background: url('../../assets/img/warning.png') no-repeat left 0;
		background-size: 120px 120px ;
		padding-left: 130px;
		font-size: 16px;
		color: #333;
		line-height: 32px;
		text-indent: 2em;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.callUsItem {
		display: flex;
		flex-direction: row;
		font-size: 18px;
		align-items: center;
		margin-top: 10px;
	}

	.moneyCard {
		display: flex;
		width: 1200px;
		margin: 20px auto;

		justify-content: space-between;

		.selectCard {
			display: flex;
			flex-direction: column;
			width: 180px;
			height: 260px;
			box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);

			.selectCardItem {
				width: 100%;
				height: 65px;
				line-height: 65px;
				text-align: center;
				font-size: 20px;
				font-weight: bold;
				background-color: #ffffff;
				cursor: pointer;
			}

			.activeSelect {
				background-color: #d1292e;
				color: #ffffff;
			}
		}

		.donateFormContent {
			box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
			background-color: #ffffff;
			width: 990px;

			.donateTitle {
				font-size: 16px;
				padding: 20px 0px 0px 60px;
			}

			.donateHint {
				font-size: 14px;
				color: #888888;
				padding: 10px 0px 0px 60px;
			}

			.formContent {
				display: flex;
				justify-content: space-between;

				.donateForm {
					width: 60%;
					margin: 20px 20px 40px 60px;

					.formItemAll {
						width: 100%;

						/deep/ .el-form-item__content {
							width: 80%;
						}
					}

					.amountMain {
						display: flex;
						justify-content: space-between;

						.amountCard {
							height: 30px;
							line-height: 30px;
							padding: 0px 15px;
							border: 1px solid #e3e3e3;
							border-radius: 5px;
							cursor: pointer;
						}

						.amountCardActive {
							height: 30px;
							line-height: 30px;
							padding: 0px 15px;
							border-radius: 5px;
							color: red;
							border: 1px solid red;
							cursor: pointer;
						}
					}

					.amountInput {
						display: flex;
						margin-top: 20px;
					}

					.btns {
						display: flex;

						.submitBtn {
							width: 40%;
						}

						.cancelBtn {
							width: 30%;
						}
					}
				}

				.formRight {
					width: calc(40% - 40px);
					padding-right: 40px;
					display: flex;
					flex-direction: column;
					margin-top: 40px;
					background-color: #fff9f9;
					padding: 20px;

					.ercodeMain {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
					}

					.formRightInput {
						display: flex;
						align-items: center;
						margin-top: 30px;

						.formRightLabel {
							width: 50px;
						}
					}

					.formRightHint {
						border: 1px dotted #ff3399;
						color: #ff3399;
						line-height: 20px;
						margin-top: 20px;
						padding: 10px;
					}
				}
			}
		}
	}

	.emailContent,
	.address,
	.remark {
		/deep/ .el-form-item__content {
			width: calc(100% - 200px);
		}
	}

	.thingRight {
		width: 950px;
		margin: 0px;
	}

	.homeLeftMenu {
		position: fixed;
		top: 40%;
		left: 0px;
		border-radius: 0px 24px 24px 0px;
		background: #ffffff;
		box-shadow: 0px 6px 18px 0px rgba(14, 14, 14, 0.27);
		padding: 10px;
		z-index: 100;

		.homeLeftMenuItem {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 30px 0px;
			cursor: pointer;
		}

		.homeLeftMenuItem:first-child {
			margin-top: 10px;
		}

		.homeLeftMenuItem:last-child {
			margin-bottom: 10px;
		}
	}
</style>
